import React, { useEffect, useState } from 'react'

function Username() {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const storedUsername = sessionStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);


  return (
    <div className='username-info'>
      <p><b>Username: </b> {username}</p>
    </div>
  )
}

export default Username
