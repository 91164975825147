import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import config from '../application/config';

const WS_URL = config.wsUrl;

class WebSocketService {
    constructor() {
        this.stompClient = null;
        this.subscribers  = {
            users: [],
            games: [],
            gameDetails: {},
            chatMesseges:{}
        }
        
    }

    connect(username) {
        const socket = new SockJS(WS_URL + username);
        this.stompClient = new Client({
          webSocketFactory: () => socket,
            onConnect: () => {
                this.subscribeToUsers();
                this.subscribeToGames();
            },
            onStompError: (frame) => {
                console.error('Broker reported error: ', frame.headers['message']);
                console.error('Additional details: ', frame.body);
            },
        });

        this.stompClient.activate();
    }

    subscribeToUsers() {
      this.stompClient.subscribe('/topic/users', (message) => {
          const data = JSON.parse(message.body);
          this.subscribers.users.forEach((callback) => callback(data));
      });
    }

    subscribeToGames() {
      this.stompClient.subscribe('/topic/games', (message) => {
        const data = JSON.parse(message.body);
          this.subscribers.games.forEach((callback) => callback(data));
      })
    }

    subscribeToTicTacToeGame(gameId, callback) {
      if (!this.subscribers.gameDetails[gameId]) {
        this.subscribers.gameDetails[gameId] = [];
      }
      this.subscribers.gameDetails[gameId].push(callback);

      const gameChannel = `/topic/game/tictactoe/${gameId}`;
      this.stompClient.subscribe(gameChannel, (message) => {
          const data = JSON.parse(message.body);
          this.subscribers.gameDetails[gameId].forEach((cb) => cb(data));
      });
    }

    subscribeToGameChat(gameId, callback) {
      if (!this.subscribers.chatMesseges[gameId]) {
        this.subscribers.chatMesseges[gameId] = [];
      }
      this.subscribers.chatMesseges[gameId].push(callback);

      const chatChannel = `/topic/game/chat/tictactoe/${gameId}`;
      this.stompClient.subscribe(chatChannel, (message) => {
          const data = JSON.parse(message.body);
          this.subscribers.chatMesseges[gameId].forEach((cb) => cb(data));
      });
    }

    subscribe(stream, callback) {
        if (stream === 'users') {
          this.subscribers.users.push(callback);
        } else if (stream === 'games') {
          this.subscribers.games.push(callback);
        }
      }
    
      unsubscribe(stream, callback) {
        if (stream === 'users') {
          this.subscribers.users = this.subscribers.users.filter((sub) => sub !== callback);
        } else if (stream === 'games') {
          this.subscribers.games = this.subscribers.games.filter((sub) => sub !== callback);
        }
      }

      unsubscribeToGameChat(gameId, callback) {
        if (this.subscribers.chatMesseges[gameId]) {
            this.subscribers.chatMesseges[gameId] = this.subscribers.chatMesseges[gameId].filter((sub) => sub !== callback);
        }
      }

      unsubscribeFromPlayingGame(gameId, callback) {
        if (this.subscribers.gameDetails[gameId]) {
            this.subscribers.gameDetails[gameId] = this.subscribers.gameDetails[gameId].filter((sub) => sub !== callback);
        }
      }
    
      disconnect() {
        if (this.stompClient) {
          this.stompClient.deactivate();
          console.log('Disconnected from WebSocket');
        }
      }
}

const webSocketService = new WebSocketService();
export default webSocketService;
