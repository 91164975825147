import axios from "axios";
import config from '../application/config';

const API_URL = config.apiUrl;

const userService = {

    getUsers: async () => {
        try {
            const response = await axios.get(`${API_URL}/connected-users`, {
                withCredentials: true
        });
            return response.data
        } catch(e) {
            console.error("Error fetching resource:", e);
            throw e;
        }
    } 
};

export default userService;