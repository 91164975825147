import React, { useEffect, useState } from 'react';
import userService from './userService';
import webSocketService from '../websocket/WebSocketService'
import './user-list.css'

function UserList() {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUsers = async () => {
    try {
      const data = await userService.getUsers();
      setUsers(data);
      setLoading(false);
    } catch (e) {
      setError(e.message);
      setLoading(false);
    }
  };

  const setupWebSocket = () => {

    const handleNewUser = (newUser) => {
      if (isUserExist) {
          removeUserFromList(newUser.username);
      }
      
      if (newUser.status !== "OFFLINE") {
          setUsers((prevUsers) => [...prevUsers, newUser]);
      }
    };
    
    webSocketService.subscribe('users', handleNewUser);

    return () => {
      webSocketService.unsubscribe('users', handleNewUser);
    };
  };

  const isUserExist = (username) => {
    return users.some(u => u.username === username);
  }

  const removeUserFromList = (username) => {
    setUsers(prevUsers => prevUsers.filter(user => user.username !== username));
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
      return setupWebSocket();
  }, []);

  useEffect(() => {
    const storedUsername = sessionStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  if (loading) return <div className='list-of-user'>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className='list-of-user'>
      <div className="title-row">
        ACTIVE USERS
      </div>
      {users.length === 0 || (users.length === 1 
      && users[0].username === username) ? (
        <div className="empty-user-row">
          No active users available.
        </div>
      ) : (
        users.map((user, index) => (
          user.username !== username && (
            <div className="user-row" key={index}>
              {user.username} | {user.status}
            </div>
          )
        ))
      )}
    </div>
  );
}

export default UserList;
