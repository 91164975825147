import axios from "axios";
import config from "../../application/config";

const API_URL = config.apiUrl;

const tictactoeService = {

    createNewGame: async () => {
        try {
            const response = await axios.post(`${API_URL}/tictactoe/create`, 
                {},
                { withCredentials: true }
            )
            return response 
        } catch(e) {
            throw e;
        }
    },

    getActiveGameByid: async (gameId) => {
        try {
            const response = await axios.get(`${API_URL}/tictactoe/active-game/${gameId}`, {
                withCredentials: true
            })
            return response.data 
        } catch(e) {
            throw e;
        }
    },

    joinToGame: async (gameId) => {
        try {
            const response = await axios.post(
                `${API_URL}/tictactoe/join-to-game/${gameId}`, 
                {},
                { withCredentials: true }
            )
            return response.status
        } catch(e) {
            throw e;
        }
    },

    makeMove: async (move) => {
        try {
            const response = await axios.post(
                `${API_URL}/tictactoe/make-move`, 
                move, 
                { withCredentials: true}
            )
            return response
        } catch(e) {
            throw e;
        }
    },

    disconnect: async (gameId) => {
        try {
            const response = await axios.post(
                `${API_URL}/tictactoe/disconnect/${gameId}`, 
                {},
                { withCredentials: true }
            )
            return response.status
        } catch(e) {
            throw e;
        }
    }


}

export default tictactoeService;