import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tictactoeService from '../games/tictactoe/tictactoeService';
import './button.css';

function CreateGameBtn() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCreateGame = async () => {
    setLoading(true);
    try { 
      const response = await tictactoeService.createNewGame();

      if(response.status === 200) {
        const newGameId = response.data
        navigate(`/tictactoe/${newGameId}`)
      } else {
        console.error('Failed to create a game');
      } 
    } catch(e) {
      console.error('Error:', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='btn-container'>
      <button 
        className='submit-btn' 
        onClick={handleCreateGame} 
        disabled={loading}
      >
        {loading ? 'Creating...' : 'New game'}
      </button>
    </div>
  )
}

export default CreateGameBtn
