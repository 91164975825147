import axios from 'axios';
import config from '../application/config';

const API_URL = config.apiUrl;

const ConnectService =  {


    checkUsernameAvailability: async (username) => {
        try {
            const response = await axios.get(API_URL + `/check-username/${username}`, {
                withCredentials: true
            });
            return response.data;
        } catch (error) {
            console.error('Error checking username availability:', error);
            return null;
        }
    },

    connect: async (username) => {
        try {
            const response = await axios.post(API_URL + `/connect-user/${username}`, {}, {
                withCredentials: true
            });
            return response.status === 200
        } catch (error) {
            
        }
    }
};

export default ConnectService;