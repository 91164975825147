import axios from "axios";
import config from '../application/config';

const API_URL = config.apiUrl;

const gameChatService = {

    sendMessage: async (message) => {
        try {
             await axios.post(API_URL + `/game-chat/send`, 
                message, 
                { withCredentials: true}
            );
        } catch(e) {
            return e.status
        }
    }
}

export default gameChatService;