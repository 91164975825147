import { Link } from 'react-router-dom';
import "./header.css"

const Header = () => {
    return (
        <div className={"header"}>
            <div className="title">
                <Link to="/"><h1>oneVone</h1></Link> 
            </div>
        </div>
    );
};

export default Header;