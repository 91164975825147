import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tictactoeService from '../games/tictactoe/tictactoeService'


function JoinBtn({ gameId }) {
  const navigate = useNavigate();

  const handleJoinToGameBtn = async () => {
    try {
      const response = await tictactoeService.joinToGame(gameId);
      if(response === 200) navigate(`/tictactoe/${ gameId }`)
    } catch (e) {
      if(e.status === 409) alert("Game is full")
    }
  }

  return (
    <div className=''>
        <button onClick={handleJoinToGameBtn} className='join-btn'>Join</button>
    </div>
  )
}

export default JoinBtn
