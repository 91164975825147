import React, { useState } from 'react';
import ConnectService from './ConnectService';
import webSocketService from '../websocket/WebSocketService';
import useSingleWindow from '../application/useSingleWindow';
import './connect.css';
import '../buttons/button.css'
import config from '../application/config';

const Connect = ({ onConnect }) => {
    const { checkIfAppIsOpenOnClick } = useSingleWindow();
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const APP_KEY = config.appKey;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isAppOpenInOtherWindow = checkIfAppIsOpenOnClick();

        if (isAppOpenInOtherWindow) {
            setErrorMessage("Application is already open in another window");
            return;
        }

        if (username && !isAppOpenInOtherWindow) { 
            const isAvailable = await ConnectService.checkUsernameAvailability(username);
            if (isAvailable) {
                const result = await ConnectService.connect(username);
                if (result) {
                    webSocketService.connect(username);
                    onConnect(username);
                    localStorage.setItem(APP_KEY, 'true');
                } else {
                    setErrorMessage(result.error);
                }
            } else if (isAvailable === null) {
                setErrorMessage('Catch server error during connection');
            } else {
                setErrorMessage('Username is already taken. Please choose another one.');

            }        
    }
    };

    return (
        <div className='containerForm'>
            <div className="connectionForm">
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Username</label>
                    <input
                        id="username"
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        placeholder='Input username'
                    />
                    <button className='submit-btn' type="submit">Connect</button>
                    <div className='error'>
                        {errorMessage && <p>{errorMessage}</p>}
                    </div>
                 
                </form>
            </div>
        </div>
    );
};

export default Connect;
