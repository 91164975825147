import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gameListService from './gameListService'
import webSocketService from '../websocket/WebSocketService'
import JoinBtn from '../buttons/JoinBtn'
import './game-list.css'


function GameList() {

    const [games, setGames] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchGames = async () => {
        try {
            const data = await gameListService.getActiveGames();
            setGames(data);
            setLoading(false);
        } catch (e) {
            setError(e.message);
            setLoading(false);
          }
    }
    
    const setupWebSocket = () => {
        const handleNewGames = (newGame) => {
            setGames((prevGames) => {
                if (newGame.status === "ABANDONED" ||
                    newGame.status === "WON" ||
                    newGame.status === "DRAW"
                ) {
                    removeGameFromList(newGame.gameId);
                    return prevGames;
                } else {
                    if (isGameExist(newGame.gameId, prevGames)) {
                        return prevGames.map(game => 
                            game.gameId === newGame.gameId ? newGame : game
                        );
                    } else {
                        return [...prevGames, newGame];
                    }
                }
            });
        };

        webSocketService.subscribe("games", handleNewGames);

        return () => {
            webSocketService.unsubscribe('games', handleNewGames)
        }
    }

    const isGameExist = (gameId, gamesList) => {
        return gamesList.some(g => g.gameId === gameId);
    };

    const removeGameFromList = (gameId) => {
        setGames(prevGames => prevGames.filter(game => game.gameId !== gameId))
    }

    useEffect(() => {
        fetchGames();
        setupWebSocket();
    }, []);
    
    if (loading) return <div className='list-of-user'>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

  return (
    <div className='list'>
        <div className='game-title-row '>
                ACTIVE GAMES
        </div>
        {games.length > 0 ? (
            games.map((game, index) => (
                <div className='game-row' key={index}>
                    <div className='game-id'>
                        {game.gameId}
                    </div>
                    <div className='game-name'>
                        {game.gameName}
                    </div>
                    <div className='status'>
                        {game.status}
                    </div>
                    <div>
                        {game.status === 'WAITING_FOR_PLAYER' ? (
                            <div className="join-btn">
                                <JoinBtn gameId={game.gameId} />
                            </div>
                        ) : (
                            <div className="watch-btn">
                                <button>
                                    <Link to={`/${game.gameName}/${game.gameId}`} >Watch</Link>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ))
        ) : (
        <div className='empty-game-row'>
            No active games available.
        </div>
        )}
    </div>
  )
}

export default GameList
