import React, { useState } from 'react';
import "./square.css"

const SquareBoard = ({ value, onClick, disabled }) => {
  return (
    <button className="square" 
    onClick={onClick} 
    disabled={disabled} 
    style={{cursor: (value !== ' ' || disabled) ? 'not-allowed' : 'pointer'}}>
      {value}
    </button>
  );
};

export default SquareBoard;