import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import tictactoeService from './tictactoeService';
import webSocketService from '../../websocket/WebSocketService';
import SquareBoard from './SquareBoard';
import './tictactoe.css'
import GameChat from '../../game-chat/GameChat';

function TicTacToe() {
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);
  const [game, setGame] = useState({});
  const { gameId } = useParams();
  const [username, setUsername] = useState('');
  const [move, setMove] = useState({
    gameId: gameId,
    row: null,
    col: null
  });
  

  const setUsernameFromSession = () => {
    const storedUsername = sessionStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
        }
  }

  const handleClick = (rowIndex, colIndex) => {
    if (game.board[rowIndex][colIndex] !== ' ') return;

    const newBoard = game.board.map((row, rIndex) =>
      row.map((value, cIndex) => {
        if (rIndex === rowIndex && cIndex === colIndex) {
          return username === game.player1 ? 'X' : "O"
        }
        return value;
      }))

    setGame((prevGame) => ({
      ...prevGame,
      board: newBoard,
      turn: username !== prevGame.player1 ? prevGame.player1 : prevGame.player2,
    }));

    const updatedMove = {
      gameId: gameId,
      row: rowIndex,
      col: colIndex,
      isWin: false
    };

    setMove(updatedMove);
    sendMoveToOponnet(updatedMove)
  }

  const sendMoveToOponnet = async (move) => {
    try {
        const response = await tictactoeService.makeMove(move);
        if (response.status === 200) setMove({});
    } catch (e) {
        console.error(e);
    }
};

  const fetchGame = async () => {
    try {
      const gameData = await tictactoeService.getActiveGameByid(gameId);
      setGame(gameData);
      setLoading(false);
    } catch(e) {
      setError(e.message);
      setLoading(false);
    }
  }

  const setupWebSocket = () => {
    const handleGameUpdate = (data) => {
      setGame(data);
    }
    webSocketService.subscribeToTicTacToeGame(gameId, handleGameUpdate);
    return () => {
      webSocketService.unsubscribeFromPlayingGame(gameId, handleGameUpdate);
    }
  }

  const handleDisconnect = async () => { 
    try {
      await tictactoeService.disconnect(gameId);
    } catch (e) {
      console.error("Error disconnecting from game:", e);
    }
  };

  
  useEffect(() => {
    setUsernameFromSession();
    fetchGame();
    setupWebSocket();

    const handleBeforeUnload = () => {
      handleDisconnect();   
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      handleDisconnect();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [])

  if (loading) return <div className='list-of-user'>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className='game-container'>

      <div>
        {game.status === "IN_PROGRESS" ? (
          <div>
            <div className='game-info-section'>
              <div className='game-info'>
                <span>Player X: </span> {game.player1}
              </div>
              <div className="game-info">
                <span>Player Y: </span>{game.player2}
              </div>
            </div>
            <div className='game-info-section'>
              <div className="game-info">
                <span>Move: </span>{game.turn}
              </div>
            </div>
          </div>
        ) : (
          <div className="game-info-section">
            <div className="game-info">
              {(() => {
                switch (game.status) {
                  case "WON":
                    return <p>Player <span>{game.winner}</span> has won!</p>;
                  case "DRAW":
                    return <p>The game ended in a draw.</p>;
                  case "WAITING_FOR_PLAYER":
                    return <p>Waiting for another player to join...</p>;
                  default:
                    return <p>Game status: {game.status}</p>;
                }
              })()}
            </div>  
          </div>
        )}
      </div>
      
        <div className="game-board">
          {game.board.map((row, rowIndex) => (
            <div key={rowIndex} className="board-row">
              {row.map((value, colIndex) => (
                <SquareBoard
                  key={colIndex}
                  value={value}
                  disabled={game.turn !== username || 
                    game.player1 === null ||
                    game.player2 === null
                  }
                  onClick={() => handleClick(rowIndex, colIndex)}
                />
              ))}
            </div>
          ))}
        </div>
        <div className="chat-container">
          <GameChat gameId={gameId} gameName={"tictactoe"} />
        </div>
    </div>
  )
}

export default TicTacToe