import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Header from './header/header';
import Connect from './connect/Connect';
import Mainpage from './mainpage/Mainpage';
import TicTacToe from './games/tictactoe/TicTacToe';
import ProtectedRoute from './application/ProtectedRoute';

function App() {
  const [username, setUsername] = useState('');

  useEffect(() => {
      const storedUsername = sessionStorage.getItem('username');
      if (storedUsername) {
          setUsername(storedUsername);
      }
  }, []);

  const handleConnect = (name) => {
      setUsername(name);
      sessionStorage.setItem('username', name);
  };

  useEffect(() => {
    sessionStorage.removeItem('username');
  }, []);

  return (
    <Router>
      <div className="app">
        <Header/>
        <div className='container'>
          <div className='components'>
            <Routes>
              <Route path="/" element={username ? <Mainpage /> : <Connect onConnect={handleConnect} />} />
              <Route path="/tictactoe/:gameId" element={
                <ProtectedRoute username={username}>
                  <TicTacToe />
                </ProtectedRoute>
                } />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
