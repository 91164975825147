import axios from "axios";
import config from '../application/config';

const API_URL = config.apiUrl;

const gameListService = {
    getActiveGames: async () => {
        try {
            const response = await axios.get(`${API_URL}/game/active`, {
                withCredentials: true
        });
            return response.data
        } catch(e) {
            console.error("Error fetching resource:", e);
            throw e;
        }
    }
}

export default gameListService