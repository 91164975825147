import { useCallback } from 'react';
import config from './config';

const APP_KEY = config.appKey;

const useSingleWindow = () => {
    const checkIfAppIsOpenOnClick = useCallback(() => {
        const isAppOpen = localStorage.getItem(APP_KEY) === 'true';

        if (isAppOpen) {
            return true; 
        } else {
            return false;
        }
    }, []);

    const handleBeforeUnload = useCallback(() => {
        localStorage.removeItem(APP_KEY);
    }, []);

    window.addEventListener('beforeunload', handleBeforeUnload);

    return {
        checkIfAppIsOpenOnClick
    };
};

export default useSingleWindow;
