import React, { useEffect, useState, useRef  } from 'react';
import webSocketService from '../websocket/WebSocketService';
import gameChatService from './gameChatService';
import './game-chat.css';

function GameChat({ gameId, gameName }) {

    const [messages, setMessages] = useState([]);
    const chatRef = useRef(null);
    const [inputMessage, setInputMessage] = useState('');

    const setupWebSocket = () => {
        const handleMessageUpdate = (data) => {
          setMessages((prevMessage) => [...prevMessage, data]);
        }
        webSocketService.subscribeToGameChat(gameId, handleMessageUpdate);
        return () => {
          webSocketService.unsubscribeToGameChat(gameId, handleMessageUpdate);
        }
      }

    useEffect(() => {
        return setupWebSocket();
    }, []);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    const handleInputChange = (event) => {
        setInputMessage(event.target.value);
    };

    const sendMessage = () => {
        const messageData = {
            gameId: gameId,
            gameName: gameName,
            message: inputMessage
        };
        gameChatService.sendMessage(messageData)
        setInputMessage('');
    };

    return (
        <div>
            <div className="chat" ref={chatRef}>
                {messages.map((m, index) => (
                    <p key={index}><span>{m.sender}</span>: {m.message}</p>
                ))}
            </div>
            
            <div className='input-section'>
                <input 
                    type="text" 
                    value={inputMessage} 
                    onChange={handleInputChange}
                />
                <button onClick={sendMessage}>SEND</button>
            </div>
            
        </div>
    );
}

export default GameChat;