import React from 'react'
import Username from '../username/Username'
import CreateGameBtn from '../buttons/CreateGameBtn';
import GameList from '../game-list/GameList';
import UserList from '../username/UserList';
import './mainpage.css';

function Mainpage() {


  return (
    <div className='container'>
        <div className='first-row'>
            <div className='username'>
              <Username/>
            </div>
            <div className='new-game-btn'>
              <CreateGameBtn/>
            </div>            
        </div>

        <div className='lists'>
          <div className='game-list'>
            <GameList/>
          </div>
          <div className='user-list'>
            <UserList/>
          </div>
        </div>
    </div>
  )
}

export default Mainpage
